import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, merge, of, reduce } from 'rxjs';
import { DocumentTypeField } from '@parashift/shared/models';
import { DocumentTypeFieldType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService, JsonApiQueryData } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => DocumentTypeField,
  endpointUrl: DocumentTypeFieldType
})
export class DocumentTypeFieldService extends BaseApiService<DocumentTypeField> {
  className = DocumentTypeFieldService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  getTenantsDocumentTypeFields(tenant_id: number) {
    return merge(
      this.getPublicDocumentTypeFields(tenant_id),
      this.getPublicDocumentTypeFields(tenant_id, 2),
      this.getPrivateDocumentTypeFields(tenant_id),
      this.getPrivateDocumentTypeFields(tenant_id, 2)
    ).pipe(
      reduce((all, batch) => all.concat(batch), [] as DocumentTypeField[])
    );
  }

  getPublicDocumentTypeFields(tenant_id: number, pageNumber = 1) {
    const page = { size: 1000, number: pageNumber };
    const filter = {
      page,
      filter: {
        discarded: 'false',
        status: tenant_id ? 'public' : undefined
      }
    };
    return this.findAll(filter, tenant_id)
      .pipe(
        map((data: JsonApiQueryData<DocumentTypeField>) => data.getModels())
      );
  }

  getPrivateDocumentTypeFields(tenant_id: number, pageNumber = 1) {
    if (!tenant_id) {
      return of([] as DocumentTypeField[]);
    }
    const page = { size: 1000, number: pageNumber };
    const filter = {
      page,
      filter: {
        discarded: 'false',
        tenant_id,
        status_not: 'public'
      }
    };
    return this.findAll(filter, tenant_id)
      .pipe(
        map((data: JsonApiQueryData<DocumentTypeField>) => data.getModels())
      );
  }

  undiscard(document_type_field_id: string, tenant_id: number) {
    const urlVars = {
      postfix: ':document_type_field_id/undiscard',
      document_type_field_id
    };
    return this.saveRecord(new DocumentTypeField(), tenant_id, urlVars);
  }

  inheritFrom(document_type_field_id: string, tenant_id: number, changedAttributes: { [name: string]: any } = {}): Observable<DocumentTypeField> {
    const new_document_type_field = new DocumentTypeField(changedAttributes);

    const urlVars = {
      postfix: 'inherit_from/:document_type_field_id',
      document_type_field_id
    };

    return this.saveRecord(new_document_type_field, tenant_id, urlVars);
  }
}
